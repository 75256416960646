import "./load_screen.css";
import "../../containers/HomeScreen/home.css";
import groupPic from "../../assets/images/camera/group2.png";

export default function Load_Screen2() {
    return(
        <div className="inner-loading-wrapper">
            <span className="top-text text-center exp-text">
            When your preview is complete, download the miniiworld app on iOS to see both of your minii personas.
            </span>
            <div className="image-holder metallic-background">
                <img src={groupPic} />
            </div>
            <p className="just-roboto text-center white-color">
            minii.me&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;minii.toon
            </p>
        </div>
    )
    
}