// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyBRNDkRxXEU4WerUoFFdoVmnBxnAKcaaEY",
    authDomain: "dreamium-miniiworld-ar.firebaseapp.com",
    databaseURL: "https://dreamium-miniiworld-ar-default-rtdb.firebaseio.com",
    projectId: "dreamium-miniiworld-ar",
    storageBucket: "dreamium-miniiworld-ar.appspot.com",
    messagingSenderId: "938841510389",
    appId: "1:938841510389:web:596e589d0f473def6590bc",
    measurementId: "G-FJZSXGB1Q5"
};



firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
let db = firebase.database()
export {auth , firebase,db};
