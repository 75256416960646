import "../../test_landing_auth_camera/auth/signup.css"
import {useEffect, useState} from "react"
import Menu from '../../test_landing_auth_camera/menu/menu'
import Input from '../../test_landing_auth_camera/menu/input'
import React from "react";
import 'firebase/auth';
import {useHistory, Link} from "react-router-dom";
import {firebase, auth, db} from '../../test_landing_auth_camera/auth/firebaseConfig';

export default function LoginPhone() {


    let history = useHistory()


    //==================PHONE=================//
    const [phone, setPhone] = useState('');
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState('');
    //==================PHONE=================//


    const [error, setError] = useState('');



    const signin = () => {
        if (phone === "" || phone.length < 10) return;
        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container',
            {
                size: "invisible"
            });
        auth.signInWithPhoneNumber(phone, verify).then((result) => {
            setfinal(result);
            alert("code sent")
            setshow(true);
        })
            .catch((err) => {
                alert(err);

            });
    }
    const ValidateOtp = () => {
        if (otp === null || final === null)
            return;
        final.confirm(otp).then((user) => {
            if (localStorage.links &&localStorage.gender) {
                let locals = localStorage.getItem("links");
                let avatar = JSON.parse(locals)
                let gender = localStorage.getItem("gender")

                db.ref(`users/${user.user.uid}`)
                    .set({
                        avatar,
                        gender,

                    }).catch(error => console.log(error.message))
            }
            history.push('/3d_show')
        }).catch((err) => {
            alert("Wrong code");
        })
    }



    useEffect(() => {
        if (error) {
            const timeId = setTimeout(() => {
                // After 3 seconds set the show value to false

                setError('')
            }, 6000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, [error]);
    useEffect(() => {
        if (error) {
            const timeId = setTimeout(() => {
                // After 3 seconds set the show value to false

                setError('')
            }, 3000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, [error]);
    return (
        <>
            <Menu/>
            <div className="login_body">
                <div>
                    <h1 className="h1__signup   " style={{fontSize:"24px",marginTop:"30%"}}>
                        login with phone number
                    </h1>
                    <div><p className="p__signup">
                        Already have an email?
                        <Link to="/login">Sign in</Link>
                    </p></div>
                </div>

                <div className='costum_div'>
                    <label className="label__signup" htmlFor="">Email or Phone Number</label>
                    <Input type="email" placeholder="Please enter your email or phone number"
                           onChange={e => setPhone(e.target.value)}/>

                </div>


                    <div >
                        <center>
                            <div style={{ display: !show ? "block" : "none",width:"90vw" }}>

                                <div id="recaptcha-container"></div>
                                <button className="form-control send_button remove_hover mt-5 button__extra--login" style={{width:"90%"}} onClick={signin}>Send OTP</button>
                            </div>
                            <div style={{ display: show ? "flex" : "none" ,width:"100vw",flexDirection:"column" }}>
                                <div className='costum_div'>
                                    <Input type="text" placeholder="Enter your OTP"
                                           onChange={e => setotp(e.target.value)}/>

                                </div>
                                <button className="form-control send_button remove_hover mt-5 button__extra--login" onClick={ValidateOtp}>Verify</button>
                            </div>
                        </center>
                    </div>



                {error ? <span className="alert alert-warning">{error.message}</span> : ''}
            </div>
        </>
    )

}
