import topImage from "../../assets/About/DeamLabs_logo.png";
import leftImage from "../../assets/About/Miniiverse_w_Protal_logo.png";

import "./about.css";

export function About() {
  return (
    <div className="container max-container">
      <div className="row part1">
        <div className="col">
          <img className="topLogo" src={topImage} alt="Dreamlabs Logo" />
        </div>
      </div>

      <div className="row part2-1">
        <div className="col colLeft">
          <h1>ABOUT US</h1>
          <p className="bold">
            We are at the dawn of the Metaverse era. Our world is becoming a
            seamless mix of digital and physical realities. One where we’ll all
            have a “digital twin” that will be our virtual persona to engage in
            this exciting evolution of the internet.
          </p>
          <p className="pbottom">
            Dreamium Labs’ mission is to unite and heal the world by enabling
            new forms of expression, collaboration and communications to
            accelerate the mass use of virtual personas. Our approach is
            philosophically rooted in sovereignty of individual identity and the
            privacy, social and economic controls that come along with truly
            owning your own digital representations. We do this through our
            minii (miniature interactive intelligent) virtual personas that
            utilize our advanced NFT tokenization system and Web 3.0 platform to
            create a comprehensive character ecosystem we call the miniiverse.
            The Dreamium Labs team is honored to share our determination,
            passion, integrity and grit to positively propel humanity forward.
            Get ready to transport into the bright new future where we feel the
            excitement, joy, wonder and prosperity of the Metaverse era, powered
            by you and your minii.
          </p>
        </div>
        <div className="col part2-2">
          <img src={leftImage} alt="left logo" />
          <p>Los Angeles</p>
        </div>
      </div>

      <div className="row part3">
        <div className="col">
          <h3>Contact</h3>
          <p>
            Partnerships: doug@dreamium.io <br />
            Investors: bob@dreamium.io <br />
            Press + Inquiries: info@dreamium.io
          </p>
        </div>
      </div>
    </div>
  );
}
