import "./creator_menu.css"
import { Link } from "react-router-dom"
import gominii_logo from "../../../assets/images/camera/gominii_logo.png"

export default function Creator_Menu() {
    return (
        <div className="creator-menu">
            <img src={gominii_logo}/>
            <Link to="/" className="exit-creator-button">
                Exit Creator</Link>
        </div>
    )
}