import react, {useEffect, useState} from "react";
import {Link} from "react-router-dom"
import "./menu.css"
export default function Menu() {
    const [state, setState] = useState(true);
    const [fall, setFall] = useState('');
    const [opacity, setOpacity] = useState('');

    useEffect(() => {
        if (state){
            setOpacity(" ")
            setFall("opacity_menu")
        }
        else {
            setOpacity(" opacity_menu")
            setFall("")
        }
    }, [state]);




    return(
        <>
            <div className={`menu ${opacity} `} onClick={()=>setState(!state)} />



          <div className={`overview ${fall}`}>
              <div className={`fall ${fall}`}>

                  <span onClick={()=>setState(!state)}>&#215;</span>
                  <a>About</a>
                  <a>Marketplace</a>
                  <a>Wallet</a>
                  <a>Dao</a>
                  <a>Roadmap</a>
                  <a>Profile</a>
                  <a>minii</a>
                  <a>cart</a>
                  <Link to='/login'>Login</Link>
              </div>

          </div>
        </>
    )

}
