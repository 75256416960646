import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./pages.css";
import Header from "../CommonLayouts/HeaderLayouts";
import Footer from "../CommonLayouts/FooterLayouts";
import TextyHeader from "../CommonLayouts/TextyHeader";

function Page() {
  return (
    <>
      <Header />
      <div className="container-fluid root-privacy">
        <TextyHeader
            text='Privacy Policy'
        />
        <br/>
        <div className="container">
          <h2>Updated: August 20, 2021</h2>
          <p>
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. <br />
            <br />
            <br />
          </p>
          <h2>interpretation and definitions</h2>
          <p>
            The words of which the initial letter is capitalized have meanings defined under the following conditions.  The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            <br />
            <br />
          </p>
          <h2>
            For The Purposes Of This Privacy Policy:
          </h2>
          <ul>
            <li>
              <strong>You</strong>&nbsp;means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            </li>
            <li>
              <strong>Company</strong>&nbsp;(referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Dreamworld , 111 century blvd, century city.
            </li>
            <li>
              <strong>Affiliate</strong>&nbsp;means an entity that controls, is controlled by or is under common control with a party, where “control” means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
            </li>
            <li>
              <strong>Account</strong>&nbsp;means a unique account created for You to access our Service or parts of our Service.
            </li>
            <li style={{ fontSize: "16px" }}>
              <strong>
                Mobile Application
              </strong>
              &nbsp; refers to miniiworld, accessible from Apple App Store and Play Store.
            </li>
            <li>
              <strong>Website</strong>&nbsp;refers to miniiworld, accessible from Https://www.gominii.com
            </li>
            <li>
              <strong>Service</strong>&nbsp;refers to the Website.
            </li>
            <li>
              <strong>Country</strong>&nbsp;refers to: California, United States
            </li>
            <li>
              <strong>Service Provider</strong>&nbsp;means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
            </li>
            <li>
              <strong>Third-party Social Media Service</strong>&nbsp;
              refers to any website or any social network website through which a User can log in or create an account to use the Service.
            </li>
            <li>
              <strong>Personal Data</strong>&nbsp;is any information that relates to an identified or identifiable individual.
            </li>
            <li>
              <strong>Cookies</strong>&nbsp;are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
            </li>
            <li>
              <strong>Device</strong>&nbsp;
              means any device that can access the Service such as a computer, a cellphone or a digital tablet.
            </li>
            <li>
              <strong>Usage Data</strong>&nbsp;refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
            </li>
          </ul>
          <h2>collecting and using your personal data</h2>
          <h2>personal data</h2>
          <p>
            While using Our Service, We may ask You to provide us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
            <ul style={{ marginLeft: "10px" }}>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Usage Data</li>
            </ul>
          </p>
          <h2>1.Personal information items to be collected</h2>
          <p>
            Personal information items to be collected by the Company are as
            follows: <br />
            <ul>
                <li style={{ marginLeft: "10px" }}>
                    Information provided by the users
                </li>
            </ul>
            The Company may collect the information directly provided by the
            users.
          </p>
          <ul>
            <li> <strong>App Personalization</strong>&nbsp; email address or phone number, password</li>
            <li> <strong>3D Avatar service</strong>&nbsp; cameras and photos: images and other information stored in the camera and photo album of the device being usedTrueDepth and facial motion capture: the Company  
                                          employs the TrueDepth API for motion capture of facial expressions that are "mirrored" on the user's avatar. The TrueDepth facial motion capture information is solely used 
                                          for the the "mirror" function and is not used for facial recognition, nor is it stored, uploaded or shared with any 3rd party.</li>
            <li>
                Information collected while the users use services Besides of information directly provided by the users, the Company may collect information in the course that the users use the service provided by the Company.
            </li>    
            <li> <strong>Equipment information</strong>&nbsp; Hardware model name, operation system, hardware version, type and set-up of browser, language of browser, information of the device being used such as mobile network information, installed application information, etc.</li>                          
            <li> <strong>Other information</strong>&nbsp; If conflicts or other errors occur while users are using the Service, the Company may exceptionally collect the information on certain devices necessary to identify the cause of such problems</li>
          </ul>
          <h2>Method of collection</h2>
          <ul>
              <li>The Company collects the information of users in a way of the followings: webpage, written form, e-mailing, tools for collection of created information</li>
              <li>Provided by partner companies</li>
          </ul>

          <h2>2. Use of collected information</h2>

          <h2>usage data</h2>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device’s Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h2>tracking technologies and cookies</h2>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tag s, and scripts to collect and
            track information and to improve and analyze Our Service.
          </p>
          <p>
            {" "}
            You can instruct Your browser to refuse all Cookies or to indicate
            when a Cookie is being sent. However, if You do not ac cept Cookies,
            You may not be able to use some parts of our Service.
          </p>
          <p>
            Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies
            rem ain on your personal computer or mobile device when You go
            offlin e, while Session Cookies are deleted as soon as You close
            your web b rowser. Learn more about cookies here: Cookies: What Do
            They Do?
          </p>
          <p>
            {" "}
            We use both session and persistent Cookies for the purposes set out
            below:
          </p>
          <p className="dot">
            <li>
              {" "}
              Necessary / Essential Cookies Type: Session Cookies/Administered
              by: Us/Purpose: These Cookies are essential to provide You with
              services available through the Website and to enable You to use
              some of its features. They help to authenticate users and prevent
              fraudulent use of user accounts. Without these Cookies, the
              services that You have asked for cannot be provided, and We only
              use these Cookies to provide You with those services.
            </li>
            <li>
              {" "}
              Cookies Policy / Notice Acceptance Cookies Type: Persistent
              Cookies/Administered by: Us/Purpose: These Cookies identify if
              users have accepted the use of cookies on the Website.
            </li>
            <li>
              Functionality Cookies Type: Persistent Cookies/Administered by:
              Us/Purpose: These Cookies allow us to remember choices You make
              when You use the Website, such as remembering your login details
              or language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </li>
          </p>
          <p>
            {" "}
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy.
          </p>
          <h2>use of your personal data</h2>
          <p>The Company may use Personal Data for the following purposes:</p>
          <p className="dot">
            <li>
              {" "}
              To provide and maintain our Service, including to monitor the
              usage of our Service.
            </li>
            <li>
              {" "}
              To manage Your Account: to manage Your registration as a user of
              the Service. The Personal Data You provide can give You access to
              different functionalities of the Service that are available to You
              as a registered user.
            </li>
            <li>
              {" "}
              For the performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or
              services You have purchased or of any other contract with Us
              through the Service.
            </li>
            <li>
              {" "}
              To contact You: To contact You by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a
              mobile application’s push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li>
              {" "}
              To provide You with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless You have opted not to receive such information.
            </li>
            <li>
              {" "}
              To manage Your requests: To attend and manage Your requests to Us.
            </li>
            <li>
              {" "}
              We may share your personal information in the following
              situations:
            </li>
            <li>
              With Service Providers: We may share Your personal information
              with Service Providers to monitor and analyze the use of our
              Service, to contact You.
            </li>
            <li>
              For Business transfers: We may share or transfer Your personal
              information in connection with, or during negotiations of, any
              merger, sale of Company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              {" "}
              With Affiliates: We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li>
              With Business partners: We may share Your information with Our
              business partners to offer You certain products, services or
              promotions.
            </li>
            <li>
              With other users: when You share personal information or otherwise
              interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed
              outside. If You interact with other users or register through a
              Third-Party Social Media Service, Your contacts on the Third-Party
              Social Media Service may see You name, profile, pictures and
              description of Your activity. Similarly, other users will be able
              to view descriptions of Your activity, communicate with You and
              view Your profile.
            </li>
          </p>
          <h2>retention of your personal data</h2>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h2>transfer of your personal data</h2>
          <p>
            Your information, including Personal Data, is processed at the
            Company’s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            {" "}
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h2>disclosure of your personal data</h2>
          <h3>business transactions</h3>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h3>law enforcement</h3>
          <p>
            {" "}
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h3>other legal requirements</h3>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <p className="dot">
            <li> Comply with a legal obligation</li>
            <li> Protect and defend the rights or property of the Company</li>
            <li>
              {" "}
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              {" "}
              Protect the personal safety of Users of the Service or the public
            </li>
            <li> Protect against legal liability</li>
          </p>
          <h2> security of your personal data</h2>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h2>children’s privacy</h2>
          <p>
            {" "}
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p>
            {" "}
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent’s consent before We collect and use that
            information.
          </p>
          <h2>links to other websites and services</h2>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party’s site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            {" "}
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <p>– Google Play Services</p>
          <p> – Unity</p>
          <p>- Appsflyer</p>
          <p>- Firebase</p>
          <p>
            Processing personal information by the Company shall be lawful only
            if and to the extent that at least one of the following applies:
          </p>
          <p className="dot">
            <li>
              {" "}
              A user has given consent to the processing of his or her personal
              information.
            </li>
            <li>
              {" "}
              Processing is necessary for the performance of a contract to which
              a user is party or in order to take steps at the request of a user
              prior to entering into a contract:
            </li>
            <li> Member management, identification, etc.</li>
            <li>
              {" "}
              Performance of a contract in relation to providing the services
              required by users, payment and settlement of fees, etc.
            </li>
            <li>
              {" "}
              Processing is necessary for compliance with a legal obligation to
              which the Company is subject
            </li>
            <li>
              {" "}
              Compliance with relevant law, regulations, legal proceedings,
              requests by the government
            </li>
            <li>
              {" "}
              Processing is necessary in order to protect the vital interests of
              users, or other natural persons
            </li>
            <li>
              {" "}
              Detection of, prevention of, and response to fraud, abuse,
              security risks, and technical issues that may harm users or other
              natural persons
            </li>
            <li>
              {" "}
              Processing is necessary for the performance of a task carried out
              in the public interest or in the excise of official authority
              vested in the Company
            </li>
            <li>
              {" "}
              Processing is necessary for the purposes of the legitimate
              interests pursued by the Company or by a third party (except where
              such interests are overridden by the interests or fundamental
              rights and freedoms of the data subject which require protection
              of personal data, in particular where the data subject is a
              child).
            </li>
          </p>
          <h2> 3. Disclosure of collected information</h2>
          <p>
            {" "}
            Except for the following cases, the Company will not disclose
            personal information with a 3rd party:
          </p>
          <p className="dot">
            <li>
              {" "}
              when the Company disclosing the information with online tailored
              advertisement providers
            </li>
            <li>
              {" "}
              When the Company automatically collects users’ ADID/IDFA to be
              provided for Firebase for experiential analysis and user
              personalization
            </li>
            <li>
              {" "}
              When the Company automatically collects users’ ADID/IDFA to be
              provided for AppsFlyer in order to analyze marketing effectiveness
            </li>
            <li>
              {" "}
              when the Company disclosing the information with its affiliates,
              partners and service providers;
            </li>
            <li>
              {" "}
              when the Company's affiliates, partners and service providers
              carry out services such as bill payment, execution of orders,
              products delivery and dispute resolution (including disputes on
              payment and delivery) for and on behalf of the Company
            </li>
            <li> when the users consent to disclose in advance;</li>
            <li>
              {" "}
              when the user selects to be provided by the information of
              products and services of certain companies by sharing his or her
              personal information with those companies
            </li>
            <li>
              {" "}
              when the user selects to allow his or her personal information to
              be shared with the sites or platform of other companies such as
              social networking sites
            </li>
            <li>
              {" "}
              other cases where the user gives prior consent for sharing his or
              her personal information
            </li>
            <li> when disclosure is required by the laws:</li>
          </p>
          <p>if required to be disclosed by the laws and regulations; or</p>{" "}
          <p>
            if required to be disclosed by the investigative agencies for
            detecting crimes in accordance with the procedure and method as
            prescribed in the laws and regulations
          </p>
          <h2>4. Cookies, Beacons and Similar Technologies</h2>
          <p>
            {" "}
            We do not use these technologies to collect the information of
            users.
          </p>
          <h2> 5. User’s right</h2>
          <p>
            The users or their legal representatives, as main agents of the
            information, may exercise the following rights regarding the
            collection, use and sharing of personal information by the Company:
          </p>
          <p className="dot">
            <li>The right to access to personal information;</li>
            <li>
              {" "}
              The users or their legal representatives may access the
              information and check the records of the collection, use and
              sharing of the information under the applicable law.
            </li>
            <li> The right to rectification;</li>
            <li>
              The users or their legal representatives may request to correct
              inaccurate or incomplete information.
            </li>
            <li> The right to erasure;</li>
            <li>
              {" "}
              The users or their legal representatives may request the deletion
              of the information after the achievement of their purpose and the
              withdrawal of their consent. To ask for deletion of your data
              please send the request by email to: support@dreamium.io or a
              physical document to: Dreamium Labs 10250 Constellation Blvd Suite
              100 Los Angeles, CA 90067
            </li>
            <li>
              {" "}
              However, Company will not be able to erase some personal data due
              to following reasons in accordance with the article 17 of the EU
              GDPR(General Data Protection Regulation).
            </li>
            <li>
              {" "}
              The processing of your personal data is necessary for compliance
              with a legal obligation.
            </li>
            <li>
              {" "}
              The processing of your personal data is necessary for the
              establishment, exercise or defense of legal claims.
            </li>
            <li> The right to restriction of processing;</li>
            <li>
              {" "}
              The users or their legal representatives may make temporary
              suspension of treatment of personal information in case of the
              disputes over the accuracy of information and the legality of
              information treatment, or if necessary to retain the information.
            </li>
            <li> The right to data portability</li>
            <li>
              {" "}
              The users or their legal representatives may request to provide or
              transfer the information.
            </li>
            <li> The right to object</li>
            <li>
              {" "}
              The users or their legal representatives may suspend the treatment
              of personal information if the information is used for the purpose
              of direct marketing, reasonable interests, the exercise of
              official duties and authority, and research and statistics.
            </li>
            <li>
              {" "}
              The right to automated individual decision-making, including
              profiling
            </li>
            <li>
              {" "}
              The users or their legal representatives may request to cease the
              automated treatment of personal information, including profiling,
              which has critical impact or cause legal effect on them.
            </li>
          </p>
          <p>
            If, in order to exercise the above rights, you, as an user, use the
            menu of 'amendment of member information of webpage or contact the
            Company by sending a document or e-mails, or using telephone to the
            Company ( person in charge of management of personal information or
            a deputy), the Company will take measures without delay: Provided
            that the Company may reject the request of you only to the extent
            that there exists either proper cause as prescribed in the laws or
            equivalent cause.
          </p>
          <h2> 6. User’s Obligations</h2>
          <p>
            The users shall keep their personal information up to date and be
            liable for the problems arising out of or in connection with the
            information incorrectly input by themselves. The users have
            responsibility to ensure and maintain the security of their
            information such as email address, Facebook ID, etc. and shall
            neither assign nor lend such information to a 3rd party. The Company
            shall not be held responsible for any problems related to personal
            information that may arise out of or in relation to the mistakes
            made by individual members or the cause not attributable to the
            Company. The users shall appropriately manage their personal
            information and take full responsibility for such management, and be
            liable for the cooperation with the request of the Company to change
            the password on a regular basis under this Policy. The users shall
            comply with the applicable laws and statues of personal information
            protection in each country.
          </p>
          <h2>7. Security</h2>
          <p>
            {" "}
            The Company regard the security of personal information of uses as
            very important. The company constructs the following security
            measures to protect the users' personal information from any
            unauthorized access, release, use or modification
          </p>
          <p className="dot">
            <li> Encryption of personal information</li>
            <li>
              Transmit users' personal information by using encrypted
              communication zone
            </li>
            <li>
              Store important information such as passwords after encrypting it
            </li>
            <li>Countermeasures against hacking</li>
            <li>
              {" "}
              Install a system in the zone the external access to which is
              controlled so as to prevent leakage or damage of users' personal
              information by hacking or computer virus
            </li>
            <li>Establish and execute internal management plan</li>
            <li> Install and operate access control system</li>
            <li>
              Take measures to prevent forging or alteration of access record
            </li>
          </p>
          <h2>8. Children</h2>
          <p>
            The Company does not knowingly collect any information from the
            children under 13 or equivalent minimum age as prescribed in the
            laws in relevant jurisdiction. The miniiworld app employs a
            compliant neutral age gate to prevent children from accessing the
            service. If miniiworld/dreamium obtains actual knowledge during the
            course of business, that the app has collected personal information
            from a child all information will be deleted from our records and
            the account will be closed. If you'd like to report an account that
            you believe belongs to someone under 13, please fill out this form.
            We take appropriate action when we receive your report.
          </p>
          <h2>9. Modification of Privacy Protection Policy</h2>
          <p>
            The Company has the right to amend or modify this Policy from time
            to time and, in such case, the Company will make a public notice of
            it through pop-up of its service (or through individual notice such
            as e-mail) and obtain consent from the users if required by relevant
            laws. If this Policy is amended, the Company will announce such
            amendment with the reason and effective date seven (7) days before
            the effective date. In the event of a change to this Privacy Policy,
            the Company will announce the amendment reason and effective date
            seven days prior to the effective date of the change.
          </p>
          <h2>10. Others</h2>
          <p>
            A. Data transfer to other countries Considering it engages in global
            businesses, the Company may provide the users' personal information
            to the companies located in other countries for the purpose as
            expressly stated in this Policy. For the places where the personal
            information is transmitted, retained or processed, the Company takes
            reasonable measures for protecting those personal information.
          </p>
          <p>The items collected by the Company are as follows:</p>
          <p className="dot">
            <li>Required information</li>
            <tr>
              <th>Title of service</th>
              <th>Items to be collected</th>
            </tr>
            <tr>
              <td>Account creation</td>
              <td>
                email address or phone number, password, date of birth, gender
              </td>
            </tr>
            <tr>
              <td>3D Avatar service</td>
              <td>
                <p>
                  cameras and photos: images and other information stored in the
                  camera and photo album of the device being used
                </p>
                <p>
                  TrueDepth and facial motion capture: the Company employs the
                  TrueDepth API for motion capture of facial expressions that
                  are "mirrored" on the user's avatar. The TrueDepth facial
                  motion capture information is solely used for the the "mirror"
                  function and is not used for facial recognition, nor is it
                  stored, uploaded or shared with any 3rd party.
                </p>
              </td>
            </tr>
            <tr>
              <td>Equipment information</td>
              <td>
                {" "}
                Hardware model name, operation system, hardware version, type
                and set-up of browser, language of browser, information of the
                device being used such as mobile network information, installed
                application information, etc.
              </td>
            </tr>
            <tr>
              <td>Other information</td>
              <td>
                {" "}
                If conflicts or other errors occur while users are using the
                Service, the Company may exceptionally collect the information
                on certain devices necessary to identify the cause of such
                problems.
              </td>
            </tr>
          </p>
          <h2>changes to this privacy policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the “Last
            updated” date at the top of this Privacy Policy.
          </p>
          <p>
            {" "}
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h2> contact us</h2>
          <p className='m-0'>
            If you have any questions about this Privacy Policy, or If you wish
            to stop using this app and have all your data removed from our and
            our service providers' servers, please email: support@dreamium.io
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Page;
