import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom"
import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import './camera.css'
import Choose from "./choose"
import ImagePreview from './imagePreview'; // source code : ./src/demo/AppWithImagePreview/ImagePreview
import arrow from "../../assets/icon.png"
import axios from "axios"; // source code :
import faceTemplate from "../../assets/images/camera/face_template.png"
import Load_Screen1 from './load_screen1';
import Load_Screen2 from './load_screen2';
import Load_Screen3 from './load_screen3';
import {useAvatarSetState} from '../../context/objectprovider'


function CameraComponent(props) {
    const [dataUri, setDataUri] = useState('');
    const [alert, setAlert] = useState({});
    const [isFullscreen, setIsFullscreen] = useState(true);
    const [text, setText] = useState('fullscreen');
    const [camera, setCamera] = useState('front');
    const [iscamera, setIscamera] = useState(true);
    const [idealFacingMode, setIdealFacingMode] = useState(FACING_MODES.USER);
    const [gender, setGender] = useState('');
    const [flag, setFlag] = useState(false);
    const [loadingPageNumber, setLoadingPageNumber] = useState(0);
    let history = useHistory()

    const setAvatar=useAvatarSetState()
//=======================================================================================
    let formdata = new FormData();


//=======================================================================================

    function handleTakePhotoAnimationDone(dataUri) {
        console.log('takePhoto');
        setDataUri(dataUri);
    }

    function takeAgainImage(dataUri) {
        setDataUri('')
        console.log('image cleaned')
    }

    function saveImage() {
        LoadingPageTurner();
        setFlag(true);
        Api(formdata).then(r => console.log(r))
        console.log('image saved')
    }

//===========================================================================
    const api = axios.create({
        baseURL: "https://api.gominii.com/api/v1/avatar/",
    });


    const axiosCreate = async (datatype) => {

        const {data: create} = await api.post(
            "/create/",
            datatype,
            {}
        );
        console.log(create);
        return create;
    };

    const axiosStatus = async (avatar, player, tokenType, accessToken) => {
        let configd = {
            "avatar_code": avatar,
            "player_code": player,
            "token_type": tokenType,
            "access_token": accessToken
        }

        var data = JSON.stringify(configd);

        const {data: status} = await api.post(
            "/status/",
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        );
        console.log('wds', status);
        return status;
    };


    const axiosExport = async (avatar, player, tokenType, accessToken) => {
        let config = {
            "avatar_code": avatar,
            "player_code": player,
            "token_type": tokenType,
            "access_token": accessToken
        }
        let data = JSON.stringify(config);

        const {data: e_code} = await api.post(`/export/`,
            data, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        console.log("this is export code", e_code);
        return e_code;
    };

    const axiosDownload = async (avatar, player, tokenType, accessToken, exportcode) => {
        let config = {
            "avatar_code": avatar,
            "player_code": player,
            "token_type": tokenType,
            "access_token": accessToken,
            "exportCode": exportcode
        }
        let data = JSON.stringify(config);

        const {data: download} = await api
            .post("/download/", data, {
                headers: {
                    'Content-Type': 'application/json'
                },
            })

        console.log("download links", download)
        setAvatar(download)
        localStorage.setItem("links",JSON.stringify(download))

        history.push('/3d_show_test')
        return download
    }


    //------------------------------------------------------------------------------------------------------------------------------

    async function Api(datatype) {
        if (gender) {
            if (gender === 'female') {

                formdata.append("name", "test from curl sample");
                formdata.append("pipeline", "body_0.3");
                formdata.append("pipeline_subtype", "mobile");
                formdata.append("parameters", "{\"model_info\":[\"skin_color\"],\"avatar_modifications\":{\"add_glare\":true,\"allow_modify_neck\":false,\"slightly_cartoonish_texture\":true,\"enhance_lighting\":true},\"shape_modifications\":{\"cartoonish_v1.0\":0.200000002980232},\"body_shape\":{\"gender\":\"female\",\"height\":1.74000000953674,\"weight\":54,\"waist\":73,\"chest\":100,\"hips\":100,\"template\":\"dreamium_body_shape_1\"}}");
                formdata.append("export_parameters", "{\"format\":\"glb\",\"embed_textures\":true,\"texture_size\":{\"width\":1024,\"height\":1024},\"haircuts\":{\"list\":[\"generated\"],\"embed\":false,\"embed_textures\":false,\"texture_size\":{\"width\":512,\"height\":512}},\"blendshapes\":{\"list\":[\"mobile_51\",\"visemes_15\"],\"embed\":true},\"additional_textures\":[\"normal_map\",\"roughness_map\",\"metallic_map\"]}");
            } else if (gender === 'male') {
                formdata.append("name", "test from curl sample");
                formdata.append("pipeline", "body_0.3");
                formdata.append("pipeline_subtype", "mobile");
                formdata.append('parameters', '{"model_info":["skin_color"],"avatar_modifications":{"add_glare":true,"allow_modify_neck":false,"slightly_cartoonish_texture":true,"enhance_lighting":true},"shape_modifications":{"cartoonish_v1.0":0.200000002980232},"body_shape":{"gender":"male","height":1.87000000476837,"weight":70,"waist":84,"chest":115,"hips":94,"template":"dreamium_body_shape_2"}}');
                formdata.append('export_parameters', '{"format":"glb","embed_textures":true,"texture_size":{"width":1024,"height":1024},"haircuts":{"list":["balding"],"embed":false,"embed_textures":false,"texture_size":{"width":512,"height":512}},"blendshapes":{"list":["mobile_51","visemes_15"],"embed":true},"additional_textures":["normal_map","roughness_map","metallic_map"]}');

            }

            const create = await axiosCreate(
                datatype
            );

            const interval = setInterval(async () => {
                const status = await axiosStatus(
                    create.avatar.code,
                    create.player.code,
                    create.auth.token_type,
                    create.auth.access_token
                );
                setAlert(status);
                if (status["status"] === "Completed" || status["progress"] === 100) {
                    clearInterval(interval);

                    const Export = await axiosExport(
                        create.avatar.code,
                        create.player.code,
                        create.auth.token_type,
                        create.auth.access_token
                    );
                    return await axiosDownload(
                        create.avatar.code,
                        create.player.code,
                        create.auth.token_type,
                        create.auth.access_token,
                        Export[0].code
                    )
                }
            }, 5000);
        }
    }

    async function LoadingPageTurner() {
        const loadingPageTotal = 3
        let currentPage = 0
        const interval = setInterval(async () => {
            if (alert["status"] === "Completed" 
                || alert["progress"] === 100 
                || currentPage+1 == 3) 
                clearInterval(interval);
            else {
                currentPage = (currentPage + 1)
                console.log(`switching to page ${currentPage}`)
                setLoadingPageNumber(currentPage)
            }
        }, 10000)
    }

    //+==========================================================================
    useEffect(() => {
        if (dataUri) {
            // setFlag(true)
            let block = dataUri.split(";");
            // Get the content type
            let contentType = block[0].split(":")[1];// In this case "image/gif"
            // get the real base64 content of the file
            let realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."

            // Convert to blob
            let blob = b64toBlob(realData, contentType);
            formdata.append("image", blob);
            //send APi
        }
    }, [dataUri]);

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    function fullscreen() {
        setIsFullscreen(!isFullscreen)
    }

    function handleCallback(childData) {
        setGender(childData);
        console.log(gender)
    }

    function change_camera() {
        setIscamera(!iscamera)
    }

    useEffect(() => {
        isFullscreen ? setText('original screen') : setText('full screen')
    }, [isFullscreen])
    useEffect(() => {

        iscamera ? setCamera('front screen') : setCamera('main screen')
        iscamera ? setIdealFacingMode(FACING_MODES.USER) : setIdealFacingMode(FACING_MODES.ENVIRONMENT)
        console.log(idealFacingMode)
    }, [change_camera])
    return (
        <>
            {(gender)
                ?
                <div>
                {(flag)
                    ?                    
                    <div className="loading-screen-wrapper">
                        {
                            {
                                0: <Load_Screen1/>,
                                1: <Load_Screen2/>,
                                2: <Load_Screen3/>
                            }[loadingPageNumber]
                        }
                        <div className="col-lg-12">
                            <p className="text-center loading-preview">Loading your minii.me preview</p>
                            <p className="text-center number-loading mt-2">{(alert.progress || 0)}%</p>
                        </div>
                    </div>           
                    :
                    (dataUri)
                        ? <div><ImagePreview dataUri={dataUri}
                                             isFullscreen={false}
                        />

                        <div >
                            <button onClick={takeAgainImage} className=" btn taked_pic--modifer" style={{left:"10px",width:"143px",height:'44px'}}>RETAKE
                            </button>
                            <button onClick={saveImage} className=" btn taked_pic--modifer" style={{right:"10px",width:"143px",height:'44px'}}>USE
                            </button>
                        </div>


                        </div>
                        :
                        <div className="take-photo position-relative">
                            <div className="face-tracker-overlay">
                                <img src={faceTemplate}/>
                            </div>
                            <div className={`arrow_camera`} onClick={()=>setGender('')}><img src={arrow} alt=""/></div>
                            <Camera onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                                    isFullscreen={isFullscreen}
                                    idealResolution={{width: 720, height: 960}}
                                    idealFacingMode={FACING_MODES.USER}
                                    isMaxResolution={true}
                            />
                            <p className="text__camera">Take a selfie to make your minii</p>
                            {/*<button onClick={fullscreen} className='btn btn-success btn_cls_camera'>{text}</button>*/}

                        </div>
                }
            </div>


                :
                <Choose showGender={handleCallback}/>

                }
        </>
    );
}

export default CameraComponent;
