import {Home} from "./landing/containers/HomeScreen";
import {About} from "./landing/containers/AboutScreen";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import ThreeDObject from "./landing/containers/threeD/threeD_render"
import ThreeDObject_test from "./landing/containers/threeD/threeD_render"
import Page from "./landing/containers/pages/page";
import CameraComponent from "./landing/containers/test_camera/test_camera"
import Tos from "./landing/containers/pages/tos/tos"
import Login from "./landing/containers/test_landing_auth_camera/auth/login"
import Contact_us from "./landing/containers/contact_us/contact_us"
import Avatar from "./landing/containers/test_camera/api/api"
import SignUp from "./landing/containers/test_landing_auth_camera/auth/signup"
import Choose from "./landing/containers/test_camera/choose"
import ObjectProvider from"./landing/context/objectprovider"
import ForgotPassword from "./landing/containers/test_landing_auth_camera/auth/forgotpass"
import Reg from"./landing/containers/threeD/registration/reg"
import LoginPhone from "./landing/containers/threeD/registration/loginwphone";
function App() {


    return (
        <div className="container-fluid">
            <ObjectProvider>
            <Router>
                <div id='body' className="container-body">

                    <Switch>
                          <Route exact path="/login_with_phone" component={LoginPhone}/>
                        <Route exact path="/forgotpass" component={ForgotPassword}/>
                        <Route exact path="/registration" component={Reg}/>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/avatar" component={Avatar}/>
                        <Route exact path="/contact_us" component={Contact_us}/>
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/sign_up" component={SignUp}/>
                        <Route exact path="/choose" component={Choose}/>
                        <Route exact path="/xxxx" component={About}/>
                        <Route exact path="/3d_show" component={ThreeDObject}/>
                        <Route exact path="/3d_show_test" component={ThreeDObject_test}/>
                        <Route exact path="/privacy-policy" component={Page}/>
                        <Route exact path="/terms-of-service" component={Tos}/>
                        <Route exact path="/camera" component={CameraComponent}/>

                    </Switch>
                </div>
            </Router>
            </ObjectProvider>
        </div>
    );
}

export default App;
