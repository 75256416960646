import react, {useEffect, useState} from 'react';
import Input from '../menu/input'
import 'firebase/auth';
import {auth, db} from './firebaseConfig';
import Menu from '../menu/menu';
import "./login.css"
import image from "../../../assets/Shape.png";
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom"

export default function Login() {
    let history = useHistory()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [show, setShow] = useState("password");
    const [flag, setFlag] = useState(true);


    let onclick_function = () => {

        auth.signInWithEmailAndPassword(email, password)
            .then((user) => {

               if (localStorage.links &&localStorage.gender){
                   let locals=localStorage.getItem("links");
                   let avatar=JSON.parse(locals)
                   let gender= localStorage.getItem("gender")

                   db.ref(`users/${user.user.uid}`)
                       .set({
                           avatar,
                           gender,

                       }).catch(error=>console.log(error.message))

                   console.log(user.user.uid)
                   history.push('/3d_show');
               }

            })
            .catch((error) => {
                setError(error)
            });
    }
    useEffect(() => {
        if (flag) {
            setShow("password")
        } else {
            setShow("text")
        }
    }, [flag])
    useEffect(() => {
        if (error) {
            const timeId = setTimeout(() => {
                // After 3 seconds set the show value to false

                setError('')
            }, 3000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, [error]);
    return (
        <>
            <Menu/>
            <div className="login_body">
                <div className="login--image "/>

                <div>
                    <div className="h1__login">Welcome back</div>
                    <div className="h2__login mb-4">Welcome Back to the gominii marketplace!</div>
                </div>
                <Input type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)}/>
                <Input type={show} placeholder='Password' onChange={(e) => setPassword(e.target.value)}/>

                <div className='staff'>
                    < img src={image} onClick={() => setFlag(!flag)} alt="dfsdfsdf"/>
                    <Link className='link_forgot' to="/forgotpass">Forgot Password ?</Link>
                    <Link className='link_forgot' to="/login_with_phone">login with phone</Link>
                </div>


                <button className="form-control send_button remove_hover mt-5 button__extra--login"
                        onClick={onclick_function}>Login
                </button>
                {error ? <span className="alert alert-warning">{error.message}</span> : ''}
                <p className="text__cteate mt-3"><Link to="/sign_up">Don’t Have an Account? Create One!</Link></p>
                <a className='link_forgot my-4'>I have a referral code </a>
            </div>
        </>
    )

}
