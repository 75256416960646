import {useState} from 'react';
import Input from '../menu/input';
import 'firebase/auth';
import "./signup.css";
import {auth} from './firebaseConfig';
export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    function handleClick() {
        if(email) {
            auth.sendPasswordResetEmail(email)
                .then(function () {
        alert('Password reset email sent')
                })
                .catch(function (error) {
console.log(error)
                });
        }
        else{
            setError("please enter a valid email address")
        }
    }
    return(
        <div style={{width:"100vw",height:"100vh"}} className="d-flex justify-content-center align-items-center flex-column">
	     <Input type="email" placeholder="Please re-enter your email "   onChange={e => setEmail(e.target.value)}/>

            <button style={{width: "90vw"}} className="form-control send_button remove_hover my-2  button__extra--login"
                    onClick={handleClick}>send change password
            </button>
            {error ? <span className="alert  alert-warning">{error}</span> : ''}

        </div>
    )

}
