import "./signup.css"
import {useEffect, useState} from "react"
import Menu from '../menu/menu'
import Input from '../menu/input'
import React from "react";
import 'firebase/auth';
import {useHistory, Link} from "react-router-dom";
import {auth} from './firebaseConfig';

export default function SignUp() {
    const [remember, setRemember] = useState(false);

    let history = useHistory()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordcheck, setPasswordcheck] = useState('');

    const [error, setError] = useState('');




    function handleClick() {
        if (remember) {
            if ( password && passwordcheck ) {
                auth.createUserWithEmailAndPassword(email, password)
                    .then((userCredential) => {
                        // send verification mail.

                        userCredential.user.sendEmailVerification();
                        auth.signOut();
                        alert("Email sent");
                        history.push('/login')
                        //TODO
                    })
                    .catch((error) => {
                        setError(error)
                    });
            } else {
                alert("your password are not match")
            }

        } else {
            alert("please accept our TOS")
        }
    }

    useEffect(() => {
        if (error) {
            const timeId = setTimeout(() => {
                // After 3 seconds set the show value to false

                setError('')
            }, 3000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, [error]);
    useEffect(() => {
        if (error) {
            const timeId = setTimeout(() => {
                // After 3 seconds set the show value to false

                setError('')
            }, 3000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, [error]);
    return (
        <>
            <Menu/>
            <div className="login_body">
                <div>
                    <h1 className="h1__signup">
                        Sign Up
                    </h1>

                </div>
                <div><p className="p__signup">
                    Already have an account?
                    <Link to="/login">Sign in</Link>
                </p></div>
                <div className='costum_div'>
                    <label className="label__signup" htmlFor="">Email</label>
                    <Input type="email" placeholder="Please enter your email "
                           onChange={e => setEmail(e.target.value)}/>

                </div>
                <div className='costum_div'>
                    <label className="label__signup" htmlFor="">Password</label>
                    <Input type="password" placeholder="Please enter 6-8 characters "
                           onChange={e => setPassword(e.target.value)}/>

                </div>
                <div className='costum_div'>
                    <label className="label__signup" htmlFor="">Re Enter Password</label>
                    <Input type="password" placeholder="Please enter 6-8 characters "
                           onChange={e => setPasswordcheck(e.target.value)}/>

                </div>
                <div className=' checkbox-round--modifer '>
                    <input type="checkbox" checked={remember} onChange={e => setRemember(e.target.checked)}
                           className="checkbox-round font_size"/>
                    <label className=" font_size--label__signup">By creating an account, you certify you have read and
                        accepted the Privacy Policy and Terms and Conditions.</label>


                </div>
                <button className="form-control send_button remove_hover mt-5 button__extra--login"
                        onClick={handleClick}>Sign Up
                </button>
                {error ? <span className="alert alert-warning">{error.message}</span> : ''}
            </div>
        </>
    )

}
