
import { Engine, Scene,StandardMaterial,SceneLoader,ArcRotateCamera, HemisphericLight,
    Vector3,Color3,DirectionalLight,MeshBuilder,ShadowGenerator} from "@babylonjs/core";

import React, { useEffect,useState, useRef } from "react";

import "@babylonjs/loaders/glTF";
import "@babylonjs/viewer";
import "./api.css"
export default function  Avatar(props) {
    const reactCanvas = useRef(null);




    useEffect(() => {
        let models=localStorage.getItem("links");
        let test=JSON.parse(models)
        let model=test.glb_file.split("model.glb")
        console.log(model)

        if (reactCanvas.current ) {
            var createScene = function() {
                // Create scene
                const scene = new Scene(engine);
                const color= scene.clearColor = new Color3(.7529, .7529, .7529);
                const camera = new ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 3, new Vector3(0, 1, 0));
                camera.attachControl(reactCanvas.current, true);

                const light = new HemisphericLight("HemiLight",new Vector3(0,0,0));
                light.intensity = 2.25;
                light.rotation = new Vector3(0,0,170);

                let light1 = new DirectionalLight("DirectionalLight", new Vector3(-1, -3,-1), scene);
                light1.intensity = 2.33;
                light1.rotation = new Vector3(0,0,70);



                var cloth_on_the_model = SceneLoader.ImportMesh('',model[0],"model.glb", scene, function(newMeshes2) {
                    var object = newMeshes2[0];

                    shadowGenerator.addShadowCaster(object, true);
                });



                reactCanvas.current.width = window.innerWidth;
                reactCanvas.current.height = window.innerHeight;


                var ground = MeshBuilder.CreateDisc("ground", {radius: 2}, scene);
                ground.rotation.x = Math.PI / 2;

                ground.material = new StandardMaterial("groundMat", scene);
                ground.material.backFaceCulling = false;
                ground.material.diffuseColor = new Color3(0.4, 0.9, 0.5);


                var shadowGenerator = new ShadowGenerator(1024, light1);
                ground.receiveShadows = true;
                shadowGenerator.useBlurExponentialShadowMap = true;
                shadowGenerator.usePercentageCloserFiltering = true;

                return scene;
            };

            var engine = new Engine(reactCanvas.current,true, { preserveDrawingBuffer: true, stencil: true });
            var scene = createScene();
            engine.runRenderLoop(() => {
                if (scene) {
                    scene.render();
                }
            });

            const resize = () => {
                scene.getEngine().resize();
            };

            if (window) {
                window.addEventListener("resize", resize);
            }

            return () => {
                scene.getEngine().dispose();

                if (window) {
                    window.removeEventListener("resize", resize);
                }
            };
        }
    });

    return (
        <>
            <canvas id="renderCanvas" ref={reactCanvas}  />

        </>

    )
};
