import "./load_screen.css";
import "../../containers/HomeScreen/home.css";
import groupPic from "../../assets/images/camera/group3.png";

export default function Load_Screen3() {
    return(
        <div className="inner-loading-wrapper">
            <span className="top-text text-center exp-text">
            In miniiworld you can customize your minii with hair styles, clothes, movements and create fun AR videos.
            </span>
            <div className="image-holder metallic-background">
                <img src={groupPic} />
            </div>
            <p className="just-roboto text-center white-color">
                minii.me
            </p>
        </div>
    )    
}