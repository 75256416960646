import { useState, useEffect } from "react";
import "./contact_us.css";

import Header from "../CommonLayouts/HeaderLayouts";
import Footer from "../CommonLayouts/FooterLayouts";
import TextyHeader from "../CommonLayouts/TextyHeader";

function Contact_us() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  function send_function() {
    let names = name.split(" ");
    let content = {
      email: email,
      fname: names[0],
      lname: names[1] || " ",
      message: text,
    };
    let json = JSON.stringify(content);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: json,
      redirect: "follow",
    };

    fetch("https://api.gominii.com/api/v1/newsletter", requestOptions)
      .then((response) => {
        if (response.status === 201) {
          setMessage("your email has been sent successfully");
        }
        if (response.status === 400) {
          setError(
            "please check your email address or use a valid email address"
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (message || error) {
      const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setMessage("");
        setError("");
      }, 7000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [message, error]);
  return (
    <>
      <Header />
      <div className="root-contact-us">
        <TextyHeader text="Support" />
        <div className="contact_us_forom form-container pt-4 ">Contact Us</div>
        <div className="form-group  pb-2 form-container">
          <input
            type="text"
            className="form-control input_place my-4 remove_hover"
            value={name}
            placeholder="Your name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            className="form-control input_place mb-4 remove_hover"
            value={email}
            placeholder=" Your e-mail address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            className="form-control input_place mb-4 remove_hover"
            style={{ height: "300px" }}
            placeholder=" Enter your message "
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <div className="row justify-content-end">
            <p
              className="btn-send"
              style={{ maxWidth: "8rem" }}
              onClick={send_function}
            >
              SEND
            </p>
          </div>

          {message ? <div className="alert alert-success">{message}</div> : ""}
          {error ? <div className="alert alert-danger">{error}</div> : ""}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Contact_us;
