import "./footer.css";
import { Link } from "react-router-dom";
import dcoin from "../../../assets/images/landing/dcoin.png";
import gominiiLogo from "../../../assets/images/landing/gominiiLogo.svg";
import dreamiumLogo from "../../../assets/images/landing/Dreamium_Lab_Logo_White.png";
import idrops from "../../../assets/images/landing/idrops.svg";
import discord from "../../../assets/images/landing/discord.png";
import tiktok from "../../../assets/images/landing/tiktok.png";
import linkedin from "../../../assets/images/landing/linkedin.png";
import instagram from "../../../assets/images/landing/instagram.png";
import twitter from "../../../assets/images/landing/twitter.png";
import { ToastContainer, toast } from "react-toastify";

export function Footer() {
  return (
    <div
      id="footer"
      className="pt-3 metallic-background-toggle-reverse overflow-hidden"
    >
      <div className="row w-100 d-lg-none l-r-padding justify-content-center pt-2 pb-2 m-0 white-color">
        <div className="col-lg-5 align-self-end">
          <div className="row justify-content-start">
            <img
              src={dreamiumLogo}
              className="img-fluid"
              style={{ maxWidth: "8rem" }}
            />
          </div>
        </div>
      </div>

      <div className="row w-100 d-lg-none l-r-padding justify-content-between pt-0 m-0 white-color">
        <div className="col-lg-12 ">
          <span
            className="exp-text footer-exp-text  mt-2"
            style={{ display: "block", paddingLeft: "0", paddingRight: "0" }}
          >
            We are at the dawn of the Metaverse era, the next generation of
            compute that harnesses all previous generations of technology and
            takes them to an exhilarating new level of interactivity, immersion,
            self-sovereignty and commerce.
            <hr style={{ background: "none" }} className="mt-2 mb-2" />
            We’ve assembled a team of passionate inventors, developers, artists
            and doers from a cross-section of the tech landscape to help build
            this next digital realm, The Dreamscape Open Metaverse.
          </span>
        </div>
        <span
          className="mt-2s"
          style={{
            display: "block",
            fontWeight: "900",
            fontSize: "1.2rem",
            paddingTop: "10px",
          }}
        >
          Our Team’s DNA
        </span>
        <div className="row p-0 bottom-list-style">
          <div className="col-5 test_test2">
            <ul>
              <li>
                <a>@2k Games</a>
              </li>
              <li>
                <a>@Activision</a>
              </li>
              <li>
                <a>@Amazon</a>
              </li>
              <li>
                <a>@Assassin's Creed</a>
              </li>
              <li>
                <a>@Broadcom</a>
              </li>
              <li>
                <a>@Disney</a>
              </li>
              <li>
                <a>@Ebay</a>
              </li>
              <li>
                <a>@Fox</a>
              </li>
              <li>
                <a>@HP / Compaq</a>
              </li>
              <li>
                <a>@Hulu</a>
              </li>
            </ul>
          </div>
          <div className="col-7">
            <ul>
              <li>
                <a>@Justice League VR</a>
              </li>
              <li>
                <a>@Playstation</a>
              </li>
              <li>
                <a>@Sega</a>
              </li>
              <li>
                <a>@Sony</a>
              </li>
              <li>
                <a>@Splinterlands</a>
              </li>
              <li>
                <a>@T-Mobile</a>
              </li>
              <li>
                <a>@USC ICT Virtual Human Project</a>
              </li>
              <li>
                <a>@Warner Brothers</a>
              </li>
              <li>
                <a>@Within</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 d-flex justify-content-end tw-mt-[30px]">
          <p className="w-100" style={{ fontSize: "12px", textAlign: "left" }}>
            All trademarks are the property of their respective owners
          </p>
        </div>
      </div>

      <div className="row w-100 d-none d-lg-flex l-r-padding justify-content-between pt-2 pb-2 m-0 white-color">
        <div className="col-lg-4 align-self-end">
          <div className="row justify-content-start">
            <img
              src={dreamiumLogo}
              className="img-fluid"
              style={{ maxWidth: "16rem" }}
            />
          </div>
        </div>
        <div className="col-lg-7 align-self-end">
          <span style={{ fontWeight: "900", fontSize: "1.8rem" }}>
            Our Team’s DNA
          </span>
        </div>
      </div>
      <div className="row w-100 d-none d-lg-flex l-r-padding justify-content-between pt-0 m-0 white-color">
        <div className="col-lg-4 ">
          <span
            className="exp-text footer-exp-text  mt-2"
            style={{ display: "block" }}
          >
            We are at the dawn of the Metaverse era, the next generation of
            compute that harnesses all previous generations of technology and
            takes them to an exhilarating new level of interactivity, immersion,
            self-sovereignty and commerce.
            <hr style={{ background: "none" }} className="mt-2 mb-2" />
            We’ve assembled a team of passionate inventors, developers, artists
            and doers from a cross-section of the tech landscape to help build
            this next digital realm, The Dreamscape Open Metaverse.
          </span>
        </div>
        <div className="col-lg-7 ">
          <div className="row bottom-list-style">
            <div className="col-lg-3 col-6">
              <ul>
                <li>
                  <a>@2k Games</a>
                </li>
                <li>
                  <a>@Activision</a>
                </li>
                <li>
                  <a>@Amazon</a>
                </li>
                <li>
                  <a>@Assassin's Creed</a>
                </li>
                <li>
                  <a>@Broadcom</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6">
              <ul>
                <li>
                  <a>@Disney</a>
                </li>
                <li>
                  <a>@Ebay</a>
                </li>
                <li>
                  <a>@Fox</a>
                </li>
                <li>
                  <a>@HP / Compaq</a>
                </li>
                <li>
                  <a>@Hulu</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6">
              <ul>
                <li>
                  <a>@Justice League VR</a>
                </li>
                <li>
                  <a>@Playstation</a>
                </li>
                <li>
                  <a>@Sega</a>
                </li>
                <li>
                  <a>@Sony</a>
                </li>
                <li>
                  <a>@Splinterlands</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6">
              <ul>
                <li>
                  <a>@T-Mobile</a>
                </li>
                <li>
                  <a>@USC ICT Virtual Human Project</a>
                </li>
                <li>
                  <a>@Warner Brothers</a>
                </li>
                <li>
                  <a>@Within</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 tw-mt-[20px]">
            <div className="row justify-content-end">
              <div className="col-6">
                <i
                  className="w-100 test_test3"
                  style={{ fontSize: "12px", textAlign: "right" }}
                >
                  All trademarks are the property of their respective owners
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row w-100 justify-content-center pt-2 pb-2 mr-2 white-color">
        <div className="col-lg-3 align-self-end"></div>

        <div className="col-lg-5"></div>

        <div className="col-lg-2 ">
          <div id="test_1_test" className="row mt-4 justify-content-center ">
            <img src={idrops} className="img-fluid footer-drop-logo" />
            <span
              className="exp-text"
              style={{ display: "block" }}
              style={{ textAlign: "center", fontFamily: "Poppins" }}
            >
              in <strong>miniiworld</strong> today <br />
              <i style={{ fontSize: "13px" }}>Web coming soon!</i>
            </span>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="row mt-2 justify-content-center">
            <img
              src={dcoin}
              className="img-fluid"
              className="dcoin-img-footer"
            />
            <span
              className="exp-text mt-1"
              style={{ display: "block" }}
              style={{ textAlign: "center" }}
            >
              Dreamium Power Ups <br />
              Purchase with DPU Tokens
            </span>
          </div>
        </div>
      </div>
      <div className="row w-100 l-r-padding justify-content-center pt-2 pb-2 m-0 white-color">
        <div className="col-lg-2 mb-2 col-xs-12 order-lg-3">
          <div className="row justify-content-center social-icon-container">
            <div className="col-8 d-flex mt-2 justify-content-center social-icons">
              <a
                href="https://discord.com/invite/9M7uTBVF4Q"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img src={discord} className="tw-max-w-[47px]" />
              </a>
              <a
                href="https://vm.tiktok.com/ZM8huVpab/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={tiktok}
                  className="footer-social-icon tw-max-w-[45px]"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/dreamium-labs/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={linkedin}
                  className="footer-social-icon tw-max-w-[45px]"
                />
              </a>
              <a
                href="https://instagram.com/go.minii?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={instagram}
                  className="footer-social-icon tw-max-w-[45px]"
                />
              </a>
              <a
                href="https://twitter.com/gominii1?s=11"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={twitter}
                  className="footer-social-icon tw-max-w-[45px]"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-xs-12 order-lg-2">
          <div className="row h-100 pt-2 pb-0 align-self-end justify-content-center align-items-center white-color">
            <div className="col-lg-4 col-4 white-color text-lg-end text-center">
              <a className="footer-link small-text" href="/privacy-policy">
                Privacy Policy
              </a>
            </div>
            <div className="col-lg-4 col-5 white-color text-center">
              <a className="footer-link small-text" href="/terms-of-service">
                Terms and Condition
              </a>
            </div>

            <div className="col-lg-4 col-3  white-color text-center text-lg-start">
              <a className="footer-link small-text" href="/contact_us">
                Support
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-xs-12 order-lg-1">
          <div className="row h-100 pt-2 pb-0 align-self-end justify-content-center align-items-center white-color">
            <a className="pl-0 ml-0 footer-link small-center tw-text-center">
              Dreamium Labs 2021
            </a>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        hideProgressBar={true}
      />
    </div>
  );
}
