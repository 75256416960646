import "./choose.css"
import {useGender } from '../../context/objectprovider'
import {useEffect, useState} from "react"
import choseMan from "../../assets/images/camera/chose_man.png";
import choseWoman from "../../assets/images/camera/chose_woman.png";
import bg from "../../assets/images/landing/metallicxsm.png";
import Creator_Menu from "./creatorMenu/creator_menu"; 

export default function Choose(props) {
    const [gender, setGender] = useState('');
    const [man, setMan] = useState(false);
    const [woman, setWoman] = useState(false);

    const{setGender_avatar}=useGender()

    useEffect(() => {
        setGender_avatar(gender)
        localStorage.setItem('gender',gender)
    if (gender ==="male"){
        setMan(true)
        setWoman(false)
    }
    else if (gender ==="female"){
        setMan(false)
        setWoman(true)
    }
    }, [gender]);
(    function handleClick(){
       if (props.showGender) props.showGender(gender)
    })()


    return(
        <>
        <div className = "choose_wrapper">
            <Creator_Menu/>
            <div className="title_choose">
                Choose your minii type
            </div>
            <div className="d-flex miniis">
                <div className="bg-container"><img src={bg} className="background"/></div>
                <div className={`box__choose choose_woman ${woman? "active--box__choose":''} `} onClick={()=>setGender("female")}>
                    <img src={choseWoman} alt="Woman minii"/>
                </div>
                <div className={`box__choose choose_man ${man? "active--box__choose":''} `} onClick={()=>setGender("male")}>
                    <img src={choseMan} alt="Man minii"/>
                </div>
            </div>
            <div className="last_choose">Your selfie camera will turn<br/>
                on after selection</div>
            <div className="line-container"><div className="line"/></div> 
        </div>
        
        </>
    )

}
