export default function Minii({
  imageUrl,
  number,
  summary,
  title,
  description,
}) {
  return (
    <div className="">
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
        <div
          className="tw-max-w-[387px] tw-max-h-[387px]"
          style={{ textAlign: "center" }}
        >
          <img
            src={imageUrl}
            className=""
            style={{
              maxWidth: "100%",
              padding: "1rem",
              boxSizing: "border-box",
            }}
          />
        </div>
        <div className="tw-flex tw-flex-col metallic-background-toggle tw-w-full tw-justify-center tw-items-center">
          <div className="tw-flex tw-justify-center">
            <p className="number-blue-border md:tw-px-0 tw-mb-[12px] tw-text-center tw-mt-[12px]">
              {number}
            </p>
          </div>
          <span
            style={{ display: "block" }}
            className="just-roboto bracket-title center-text"
          >
            [ {summary} ]
          </span>
          <strong className="center-text strong-text">{title}</strong>

          <p className="just-roboto middle-image-explanation white-color text-center tw-mb-[20px]">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}
