import "../../test_landing_auth_camera/auth/signup.css"
import {useEffect, useState} from "react"
import Menu from '../../test_landing_auth_camera/menu/menu'
import Input from '../../test_landing_auth_camera/menu/input'
import React from "react";
import 'firebase/auth';
import {useHistory, Link} from "react-router-dom";
import {firebase, auth, db} from '../../test_landing_auth_camera/auth/firebaseConfig';

export default function Reg() {
    const [remember, setRemember] = useState(false);

    let history = useHistory()

    const [input, setInput] = useState('');
    const [email, setEmail] = useState('');
    //==================PHONE=================//
    const [phone, setPhone] = useState('');
    const [otp, setotp] = useState('');
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState('');
    //==================PHONE=================//
    const [password, setPassword] = useState('');
    const [passwordcheck, setPasswordcheck] = useState('');

    const [error, setError] = useState('');

    function validateEmail(event) {
        console.log(event.target.value)
        if (event.target.value!==null) {
            const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
             if (re.test(String(event.target.value).toLowerCase())) { setEmail(event.target.value)
            setPhone('')
            } else{ setEmail('')}
        }
     if (event.target.value.match(/\d/g)) {
	         (event.target.value.match(/\d/g).length >= 10) ? setPhone(event.target.value) : setPhone('')
     }
    }

    const signin = () => {

        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container',
            {
                size: "invisible"
            });
        auth.signInWithPhoneNumber(phone, verify).then((result) => {
            setfinal(result);
            alert("code sent")
            setshow(true);
        })
            .catch((err) => {
                alert(err);

            });
    }
    const ValidateOtp = () => {
        if (otp === null || final === null)
            return;
        final.confirm(otp).then((user) => {
            if (localStorage.links &&localStorage.gender) {
                let locals = localStorage.getItem("links");
                let avatar = JSON.parse(locals)
                let gender = localStorage.getItem("gender")

                db.ref(`users/${user.user.uid}`)
                    .set({
                        avatar,
                        gender,

                    }).catch(error => console.log(error.message))
            }
            history.push('/3d_show')
        }).catch((err) => {
            alert("Wrong code");
        })
    }

    function handleClick() {
        if (remember) {
            if (email) {
                if (password && password === passwordcheck) {
                    auth.createUserWithEmailAndPassword(email, password)
                        .then((userCredential) => {
                            // send verification mail.
                            userCredential.user.sendEmailVerification();
                            auth.signOut();
                            alert("Email sent");
                            history.push('/login')

                        })
                        .catch((error) => {
                            setError(error)
                        });
                } else {
                    alert("your password are not match")
                }
            }

        } else {
            alert("please accept our TOS")
        }
    }

    useEffect(() => {
        if (error) {
            const timeId = setTimeout(() => {
                // After 3 seconds set the show value to false

                setError('')
            }, 3000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, [error]);
    useEffect(() => {
        if (error) {
            const timeId = setTimeout(() => {
                // After 3 seconds set the show value to false

                setError('')
            }, 3000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, [error]);
    return (
        <>
            <Menu/>
            <div className="login_body">
                <div>
                    <h1 className="h1__signup   " style={{fontSize:"24px",marginTop:"30%"}}>
                        Complete Registration
                    </h1>
                    <div><p className="p__signup">
                        Already have an account?
                        <Link to="/login">Sign in</Link>
                    </p></div>
                </div>

                <div className='costum_div'>
                    <label className="label__signup" htmlFor="">Email or Phone Number</label>
                    <Input type="email" placeholder="Please enter your email or phone number"
                           onChange={e => validateEmail(e)}/>

                </div>
                {(email)
                ?
                    <div>
                    <div className='costum_div'>
                    <label className="label__signup" htmlFor="">Password</label>
                    <Input type="password" placeholder="Please enter 6-8 characters "
                           onChange={e => setPassword(e.target.value)}/>

                </div>
                <div className='costum_div'>
                    <label className="label__signup" htmlFor="">Re Enter Password</label>
                    <Input type="password" placeholder="Please enter 6-8 characters "
                           onChange={e => setPasswordcheck(e.target.value)}/>

                </div>
                        <div className=' checkbox-round--modifer ' style={{marginTop: "-5px"}}>
                            <input type="checkbox" checked={remember} onChange={e => setRemember(e.target.checked)}
                                   className="checkbox-round font_size"/>
                            <label className=" font_size--label__signup">By creating an account, you certify you have read and
                                accepted the Privacy Policy and Terms and Conditions.</label>


                        </div>



                        <button className="form-control send_button remove_hover mt-5 button__extra--login"
                                onClick={handleClick}>Sign Up
                        </button>
                        {/*<p  className={`font_size--label__signup my-4`} style={{color:"#494949",fontSize:'14px'}}>A link will be sent to your email for easy retrival of your avatar. </p>*/}
                    </div>:<div style={{marginTop:"70%"}}></div>}
                {(phone)
                ?
                    <div >
                        <center>
                            <div style={{ display: !show ? "block" : "none",width:"90vw" }}>

                                <div id="recaptcha-container"></div>
                                <button className="form-control send_button remove_hover mt-5 button__extra--login" style={{width:"90%"}} onClick={signin}>Send OTP</button>
                            </div>
                            <div style={{ display: show ? "flex" : "none" ,width:"100vw",flexDirection:"column" }}>
                                <div className='costum_div'>
                                    <Input type="text" placeholder="Enter your OTP"
                                           onChange={e => setotp(e.target.value)}/>

                                </div>
                                <button className="form-control send_button remove_hover mt-5 button__extra--login" onClick={ValidateOtp}>Verify</button>
                            </div>
                        </center>
                    </div>:''

                }

                {error ? <span className="alert alert-warning">{error.message}</span> : ''}
            </div>
        </>
    )

}
