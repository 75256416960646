import "./input.css";
export default function Input(props){
    function handleChange(e) {
        if (props.onChange) props.onChange(e)

    }

return(
    <>
        <input className='input_general ' type={props.type}  placeholder={props.placeholder}  onChange={handleChange}/>
    </>
)
}
