import React, { useRef, useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import "../threeD/tabs/popup.css";
import homeVideo from "../../assets/Main/1YB_Website_RappingVId.mp4";
import logo from "../../assets/Main/MiniiLogo.png";

import image1 from "../../assets/Main/SelfieMock_Iphone1.png";
import image2 from "../../assets/Main/DanceMock_Iphone.png";

import imageLeft from "../../assets/Main/MiniworldLogo.png";
import imageRight from "../../assets/Main/MiniverseLogo.png";

import Header from "../CommonLayouts/HeaderLayouts";
import Timer from "../CommonLayouts/Timer";
import Footer from "../CommonLayouts/FooterLayouts";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import gominiiLogo from "../../assets/images/landing/gominiiLogo.svg";
import idrops from "../../assets/images/landing/idrops.svg";
import topMiniis from "../../assets/images/landing/topMiniis.png";
import miniTextLogo from "../../assets/images/landing/miniTextLogo.svg";
import womanFace from "../../assets/images/landing/womanFace.png";
import middleGirls from "../../assets/images/landing/middleGirls.png";
import wonderWoman from "../../assets/images/landing/wonderWoman.png";
import maskGroup from "../../assets/images/landing/maskGroup.png";
import appleDownloadBtn from "../../assets/images/landing/appleDownloadBtn.png";
import phones from "../../assets/images/landing/phones.png";
import zooYork from "../../assets/images/landing/zooYork.png";
import rock from "../../assets/images/landing/rock.png";
import coins from "../../assets/images/landing/coins.png";
import dcoin from "../../assets/images/landing/dcoin.png";
import flagiMiniis from "../../assets/images/landing/flagiMiniis.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import discord from "../../assets/images/landing/discord.png";
import mutantOrigins from "../../assets/images/landing/mutant_origins.png";
import twoMutants from "../../assets/images/landing/two_mutants.png";
import miniiverseText from "../../assets/images/landing/mutants_of_the_miniiverse.svg";
import "../../../index.css";
import Minii from "./Minii";

const miniis = [
  {
    imageUrl: womanFace,
    number: 1,
    summary: "CREATE FOR FREE",
    title: "Just a Simple Selfie",
    description:
      "To get started make your minii now! You can transport your minii into our app for more advanced features.",
  },
  {
    imageUrl: middleGirls,
    number: 2,
    summary: "YOU CAN BE YOU",
    title: "Choose your Unique DNA",
    description: `You can choose hair styles, clothes, accessories and more. \n Play with your minii for free or upgrade your DNA.`,
  },
  {
    imageUrl: wonderWoman,
    number: 3,
    summary: "GAME ON",
    title: "Play and Earn!",
    description:
      "Your minii is now ready to earn while you play in the miniiverse across all apps once the Dreamscape Open Metaverse is launched.",
  },
];

export function Home() {
  const pointRef = useRef(null);
  const videoRef = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const [flag, setflag] = useState(false);
  const location = useLocation();
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
  // const [support, setSupport,] = useState(true);

  let history = useHistory();

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function createMiniiClicked() {
    console.log(width);
    if (width < 600) {
      history.push("/camera");
    } else {
      setflag(true);
    }
  }

  useEffect(() => {
    const executeScroll = () => pointRef.current.scrollIntoView();

    if (location.pathname !== "/xxxx") {
      if (window.location.href.endsWith("/#join")) {
        executeScroll();
      }
    }
  });

  return (
    <>
      <Header createMiniiClicked={createMiniiClicked} />
      {flag && (
        <div className="popup-box tw-z-10">
          <div
            className="box"
            style={{
              width: "400px",
              height: "356px",
              backgroundColor: "#242424",
            }}
          >
            <div className="popup_text_title">Create your minii</div>
            <span
              className="close-icon mt-1 mr-1"
              onClick={() => setflag(false)}
            >
              <img src="/close.png" />
            </span>
            <div className="mb-3">
              <img src="/Logo1.png" width="100px" height="100px" alt="" />
            </div>
            <div className="popup_text_main mb-2">
              To create your minii use your mobile phone or download the
              miniiworld app below.
            </div>
            <div
              className="popup_text_main"
              style={{ fontSize: "16px", lineHeight: "28px" }}
              href="https://apps.apple.com/us/app/miniiworld/id1529010815"
            >
              get the app
            </div>

            <div className="">
              <a
                href="https://apps.apple.com/us/app/miniiworld/id1529010815"
                target="_blank"
              >
                <img src="/apple_logo.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="body-root">
        {/* First */}
        <div className="tw-flex tw-flex-col md:tw-flex-row mb-2 w-100 p-0 m-0 l-r-margin l-r-padding  white-color metallic-background">
          <div className="black-background tw-flex tw-flex-col tw-justify-center tw-items-center md:tw-ml-[61px] tw-order-2 md:tw-order-1">
            <div className="tw-flex tw-flex-col">
              <span
                className="exp-text mt-2 w-100 tw-px-4 md:tw-px-0"
                style={{ display: "block" }}
              >
                [ BE AN NFT ]
              </span>
              <p className="w-100 mt-2 bold-big-text mb-0 tw-px-4 md:tw-px-0">
                The Metaverse starts with you and
                <span className="text-btn-blue"> all your </span>
                <img
                  src={miniTextLogo}
                  className="minii-image-text tw-inline-block"
                />
                personas
              </p>
              <span
                className="exp-text mt-2 tw-px-4 md:tw-px-0"
                style={{ display: "block" }}
              >
                Become your own set of minii NFT personas on the first
                Decentralized Universal NFT Character System (dUNCS) that
                authenticates your unique digital DNA and transports all your
                aesthetics, skills, powers, rankings and items privately and
                securely across the web.
              </span>
            </div>
          </div>
          <div className="tw-flex tw-justify-center tw-items-center md:tw-min-w-[682px] md:tw-min-h-[550px] tw-relative tw-order-1 md:tw-order-2">
            <img
              className="md:tw-min-w-[682px] md:tw-absolute md:tw-inset-0"
              src={topMiniis}
            />
          </div>
        </div>
        {/* Second  */}
        <hr className="splitter d-lg-none" />
        <div className="tw-flex tw-flex-col white-color tw-px-4 md:tw-px-[61px]">
          <span className="exp-text mt-2" style={{ display: "block" }}>
            [ EARN WHILE YOU PLAY ]
          </span>
          <p className="mt-2 bold-big-text mb-0">
            Your
            <span>
              <img
                src={miniTextLogo}
                className="minii-image-text tw-inline-block"
              />
            </span>
            is simply
            <span className="text-btn-blue"> freewarding </span>
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-justify-around md:tw-flex-row tw-flex-wrap white-color">
          {miniis.map((minii, index) => {
            return (
              <Minii
                imageUrl={minii.imageUrl}
                number={minii.number}
                summary={minii.summary}
                title={minii.title}
                description={minii.description}
              />
            );
          })}
        </div>

        {/* Third */}
        {/* This section has two different design for large and small screens */}
        {/* Quest DIV for screens other than phones  */}
        <div
          className="tw-flex tw-flex-row w-100 white-color
        metallic-background d-none d-lg-flex tw-pb-[40px]"
        >
          <div className="tw-ml-[61px]">
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
              <img
                src={maskGroup}
                className="md:tw-max-w-[479px] md:tw-max-h-[479px]"
              />
            </div>
            <div className="tw-flex tw-flex-row tw-gap-[10px] tw-mt-[5px]">
              <div className="">
                <div className="tw-flex tw-flex-col justify-content-end">
                  <a className="btn-create-minii" onClick={createMiniiClicked}>
                    Create your minii
                  </a>
                </div>
              </div>
              <div className="">
                <a
                  className="text-center"
                  href="https://apps.apple.com/us/app/miniiworld/id1529010815"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appleDownloadBtn} className="download-apple" />
                </a>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-justify-center tw-items-center small-black-background tw-ml-[50px] md:tw-mr-[20px]">
            <div className="tw-flex tw-flex-col">
              <span
                style={{ display: "block" }}
                className="bracket-title just-roboto w-100"
              >
                [ GAME ON ]
              </span>
              <p className="w-100 mt-2 bold-big-text mb-0">
                Your
                <span className="blue-color"> quest </span>
                begins now in miniiworld
              </p>
              <span className="exp-text mt-2" style={{ display: "block" }}>
                Download miniiworld, the first miniiverse app running on a beta
                version of the Dreamscape Open Metaverse.
                <hr style={{ background: "none" }} className="mt-1 mb-1" />
                Create engaging Augmented Reality videos starring you and all
                your friends’ miniis. Post on TikTok and Instagram with the{" "}
                <strong className="blue-color" style={{ fontWeight: "bold" }}>
                  {" "}
                  #gominii.{" "}
                </strong>
                The more audience your video attracts, the more Social Clout
                powers you can obtain.
                <hr style={{ background: "none" }} className="mt-1 mb-1" />
                Your minii will develop valuable skills that power-up its DNA
                when minting occurs allowing you to earn more rewards when the
                Dreamscape Open Metaverse launches. By amplifying your Social
                Clout with Dreamium Power-Up Outfit drops, your video’s audience
                scores multiply for extra bonus rewards.
              </span>
            </div>
          </div>
        </div>

        {/* Quest DIV for phone screens */}
        <div className="tw-flex tw-flex-col w-100 p-0 m-0 mt-1 mb-1 l-r-padding white-color d-lg-none">
          <hr className="splitter d-lg-none mt-3" />
          <div className="col-12 mb-3 tw-pl-4 md:tw-pl-0">
            <span style={{ display: "block" }} className="just-roboto w-100">
              [ GAME ON ]
            </span>
            <p className="w-100 bold-big-text mb-0">
              Your
              <span className="blue-color"> quest </span>
              begins now in miniiworld
            </p>
          </div>
          <div className="col-lg-5 metallic-background">
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
              <img
                className="tw-max-w-[283px] tw-max-h-[283px] "
                src={maskGroup}
              />
            </div>
          </div>
          <div className="col-lg-7 d-flex justify-content-center align-items-center small-black-background tw-pl-4">
            <div className="tw-flex tw-flex-col">
              <span
                className="exp-text"
                style={{ display: "block", paddingTop: "10px" }}
              >
                Download miniiworld, the first miniiverse app running on a beta
                version of the Dreamscape Open Metaverse. <br />
                <br />
                <hr style={{ background: "none" }} className="mt-1 mb-1" />
                Create engaging Augmented Reality videos starring you and all
                your friends’ miniis. Post on TikTok and Instagram with the{" "}
                <strong className="blue-color" style={{ fontWeight: "bold" }}>
                  {" "}
                  #gominii.{" "}
                </strong>
                The more audience your video attracts, the more Social Clout
                powers you can obtain. <br />
                <br />
                <hr style={{ background: "none" }} className="mt-1 mb-1" />
                Your minii will develop valuable skills that power-up its DNA
                when minting occurs allowing you to earn more rewards when the
                Dreamscape Open Metaverse launches. By amplifying your Social
                Clout with Dreamium Power-Up Outfit drops, your video’s audience
                scores multiply for extra bonus rewards.
              </span>
            </div>
          </div>
          <div className="tw-flex tw-flex-col">
            <div className="col-12 mb-3 mt-3 d-flex justify-content-center">
              <div className="tw-flex tw-flex-col justify-content-end">
                <a className="btn-create-minii" onClick={createMiniiClicked}>
                  Create your minii
                </a>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <a
                className="text-center"
                href="https://apps.apple.com/us/app/miniiworld/id1529010815"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appleDownloadBtn} style={{ width: "8rem" }} />
              </a>
            </div>
          </div>
        </div>
        {/* The second for third tw-flex tw-flex-col */}
        <div
          className="tw-flex tw-flex-row tw-justify-center tw-items-center mb-2 w-100 p-0 m-0 white-color d-none d-lg-flex overflow-hidden"
          style={{ position: "relative" }}
        >
          <div className="col-lg-5 order-lg-2 r-padding">
            <img className="w-100" src={phones} className="phones-image" />
          </div>
          <div className="l-padding black-background tw-flex order-lg-1">
            <div className="tw-flex tw-flex-col">
              <span
                style={{ display: "block", padding: "0" }}
                className="bracket-title just-roboto w-100"
              >
                [ POWER UP YOUR PERSONA ]
              </span>
              <p className="mt-2 bold-big-text mb-0">
                NFT Transformables
                <span className="blue-color"> level-up </span>
                <div>
                  your
                  <img
                    className="tw-inline-block"
                    src={miniTextLogo}
                    className="minii-image-text tw-inline-block"
                  />
                </div>
              </p>
              <span className="exp-text mt-2" style={{ display: "block" }}>
                Each Transformable carries its own powers that can transform
                your minii’s capabilities to conquer every new quest. The first
                collection of Transformables will power up your Social Clout
                quest with collectible streetwear from iconic designers in skate
                and hip-hop cultures. After all, your minii has to be snatched
                if you want <span style={{ fontWeight: "bolder" }}>cred</span>!
                <div className="image_text_flex">
                  <img src={zooYork} className="badgi-images" />
                  <img
                    src={rock}
                    className="badgi-images"
                    style={{ marginLeft: "1.5rem" }}
                  />
                </div>
              </span>
            </div>
          </div>
        </div>

        <div
          className="tw-flex tw-flex-col  mb-3 w-100 p-0 m-0 white-color d-lg-none"
          style={{ position: "relative" }}
        >
          <div
            className="badge-holder-sm"
            style={{
              textAlign: "right",
              alignItems: "center",
              marginTop: "35%",
              marginRight: "30px",
              marginBottom: "auto",
            }}
          >
            <img src={zooYork} className="increase_image" /> <br />
            <img src={rock} className="mt-2 increase_image" />
          </div>
          <hr className="splitter d-lg-none mt-3" />
          <div className="col-12 tw-px-4">
            <span style={{ display: "block" }} className="just-roboto w-100">
              [ POWER UP YOUR PERSONA ]
            </span>
            <p className="w-100 mt-2 bold-big-text mb-0">
              NFT Transformables
              <span className="blue-color"> level-up </span>
              <div>
                your
                <img
                  src={miniTextLogo}
                  className="minii-image-text tw-inline-block"
                />
              </div>
            </p>
          </div>
          <div className="col-lg-5 mb-1 mt-4 order-lg-2 metallic-background tw-px-4">
            <img className="w-100" src={phones} className="phones-image" />
          </div>
          <div className="col-lg-7 black-background d-flex justify-content-center align-items-center order-lg-1 tw-px-4">
            <div className="tw-flex tw-flex-col">
              <span className="exp-text" style={{ display: "block" }}>
                Each Transformable carries its own powers that can transform
                your minii’s capabilities to conquer every new quest.
                <br />
                <br />
                The first collection of Transformables will power up your Social
                Clout quest with collectible streetwear from iconic designers in
                skate and hip-hop cultures.
                <br />
                <br />
                After all, your minii has to be snatched if you want{" "}
                <span
                  style={{
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  cred!
                </span>
              </span>
            </div>
          </div>
        </div>

        {/* <div
          className="tw-flex tw-flex-col w-100 l-r-padding pt-2 m-0 mt-1 white-color metallic-background"
          style={{ position: "relative" }}
        >
          <div className="col-lg-5">
            <div className="tw-flex tw-flex-col justify-content-center">
              <img src={twoMutants} className="img-fluid mb-4 mt-2" />
            </div>
          </div>

          <div
            className="col-lg-7 justify-content-center align-items-center"
            style={{ position: "relative" }}
          >
            <div className="mutants-of-the-miniiverse">
              Mutants of the miniiverse
            </div>
            <div className="tw-flex tw-flex-col mt-3 mb-3 hide-mobile">
              <span
                style={{ display: "block", paddingTop: "10px" }}
                className="bracket-title just-roboto w-100 test_test"
              >
                [ BE FIRST TO PLANT YOUR FLAG IN THE METAVERSE ]
              </span>
              <p className="w-100 mt-2 bold-big-text mb-0">
                Something
                <span className="blue-color"> unexpected </span>
                awaits
              </p>
              <span className="exp-text mt-2" style={{ display: "block" }}>
                Mutants of the Metaverse are powerful mutant themed minii NFT
                collectibles that live in an intergalactic and cross-chain
                space. Each mutant is uniquely generated with its own serial
                number and possesses traits that can be used across the
                Dreamscape Open Metaverse. In the soon to be released narrative,
                THE ORIGINS, by Dr. Gene Block, you can learn about their
                genesis, special powers and how they became the first
                inhabitants of the Metaverse.
              </span>
            </div>
            <div className="tw-flex tw-flex-col m-0 mt-5 mb-1 justify-content-center">
              <a className="btn-join-whitelist" href="https://getmutated.com">
                Join the Whitelist
              </a>
            </div>
          </div>
        </div> */}
        <div className="whitelist-section metallic-background">
          <div className="whitelist-section-logo">
            <img
              src={twoMutants}
              className="two-mutants-image md:tw-max-w-[346px]"
            />
            <div className="mutants-of-the-miniiverse">
              Mutants of the miniiverse
            </div>
          </div>
          <div className="whitelist-main">
            <div className="whitelist-main-text">
              <p>[ BE FIRST TO PLANT YOUR FLAG IN THE METAVERSE ]</p>
              <p className="w-100 mt-2 bold-big-text mb-0">
                Something
                <span className="blue-color"> unexpected </span>
                awaits
              </p>
              <span className="exp-text mt-2" style={{ display: "block" }}>
                Be among the first 100,000 of the 4 billion internet connected
                humans to transport into the Metaverse. Something unexpected
                happens to these pioneers as their physical DNA transforms into
                a digital minii NFT. The results will shock the world as new
                super-race of minii Mutants is spawned.
              </span>
            </div>
            <a
              className="btn-join-whitelist"
              href="https://getmutated.com"
              target="_blank"
            >
              Learn more
            </a>
          </div>
        </div>
        {/* Discord link for large screens */}
        <div className="tw-flex tw-flex-col tw-justify-center tw-py-8 d-none d-lg-flex m-0 mt-1 white-color metallic-background-toggle">
          <div className="tw-flex tw-justify-center tw-items-center tw-gap-[30px]">
            <p
              className="bold-big-text"
              style={{ textAlign: "center", marginLeft: "-45px" }}
            >
              Join our community on
              <span className="blue-color"> Discord </span>
            </p>
            <a
              href="https://discord.com/invite/9M7uTBVF4Q"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={discord} className="tw-max-w-[45px]" />
            </a>
          </div>
        </div>
        {/* Discord link for small screens */}
        <div className="tw-flex tw-flex-col w-100 tw-justify-center tw-py-2 d-lg-none m-0 mt-1 white-color metallic-background">
          <div className="">
            <p className="text-center mt-3 bold-big-text discord-community-text mb-0">
              Join our community on
              <span className="blue-color"> Discord </span>
            </p>
          </div>
          <div className="col-12 mt-4 mb-3 d-flex justify-content-center">
            <a
              href="https://discord.com/invite/9M7uTBVF4Q"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <img src={discord} className="discord-middle-image" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
