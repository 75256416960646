import React from "react";
import "./style.css"
export default function TextyHeader(props) {
  return (
    <>
      <div className="justify-content-center align-items-center metallic-background-privacy policy-header">
        <div
          style={{ height: "inherit" }}
          className=" w-100 col-12 d-flex justify-content-start align-items-center"
        >
          <div className="ml-3 text_fontsize_header" >{props.text}</div>
        </div>
      </div>
    </>
  );
}
