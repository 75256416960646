import {
  Engine,
  Scene,
  AssetsManager,
  AssetTaskState,
  ArcRotateCamera,
  Vector3,
  DirectionalLight,
  CubeTexture,
  Color4,
} from "@babylonjs/core";

import React, { useEffect, useState, useRef } from "react";
import "./threeD_render.css";
import "@babylonjs/loaders";
import "@babylonjs/viewer";
import { useHistory } from "react-router-dom";
import { auth, db } from "../test_landing_auth_camera/auth/firebaseConfig";
import Creator_Menu from "../test_camera/creatorMenu/creator_menu";

export default function ThreeDObject_test(props) {
  let history = useHistory();
  const reactCanvas = useRef(null);

  const [cloth, setCloth] = useState("");
  const [user, setUser] = useState(true);
  const [model, setModel] = useState("");
  const [hair, setHair] = useState("");
  
  const [isOpen, setIsOpen] = useState(false);

  const baseUrl = "https://lucidassets.s3.us-west-2.amazonaws.com/"
  const stageUrl = `${baseUrl}Babylon/Assets/stage.glb`
  const maleDefaultHair = `${baseUrl}Uploads/FinalAssets/dev/Outfit/Cartoonish/Male/mohawk_red/mohawk_red.glb`
  const maleDefaultClothes = `${baseUrl}FinalAssets/Test/Outfits/male/Male_hero_outfit_model.glb`
  const femaleDefaultClothes = `${baseUrl}Uploads/FinalAssets/dev/Outfit/Realistic/Female/Female_hero_outfit_model/Babylon/Female_hero_outfit_model.glb`
  const femaleDefaultHair = `${baseUrl}Uploads/FinalAssets/dev/Hair/Cartoonish/Female/undercut_blue/Babylon/undercut_blue.glb`
  
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let user;
    if (auth.currentUser) user = auth.currentUser.uid;
    if (user) {
      db.ref(`users/${user}`).once("value", (snapshot) => {
        let avatar = [];
        snapshot.forEach((snap) => {
          avatar.push(snap.val());
        });
        console.log(avatar);
        if (model === "") setModel(avatar[0].glb_file);
        if (avatar[1]) {
          localStorage.setItem("gender", avatar[1]);
          if (avatar[1] === "male") {
            setCloth(maleDefaultClothes);
            setHair(maleDefaultHair);
          } else if (avatar[1] === "female") {
            setCloth(femaleDefaultClothes);
            setHair(femaleDefaultHair);
          }
        }        
        if (avatar[0]) localStorage.setItem("links", JSON.stringify(avatar[0]));
      });
    } else {
      let gender = localStorage.getItem("gender");
      let locals = localStorage.getItem("links");
      let test = JSON.parse(locals);
      let avatar = test.glb_file;

      if (gender) {
        if (gender === "male") {
          setCloth(maleDefaultClothes);
          setHair(maleDefaultHair);
        } else if (gender === "female") {
          setCloth(femaleDefaultClothes);
          setHair(femaleDefaultHair);
        }
      }
      if (model === "") setModel(avatar);
    }
  }, []);

  useEffect(() => {
    if (reactCanvas.current && model && cloth && hair) {
      initiateScene();
      return () => {};
    }
  }, [reactCanvas, model, cloth, hair]);

  async function initiateScene() {
    if (!model && !cloth && !hair) return;
    var createScene = async function (engine) {
      let scene = new Scene(engine);
      //Load Assets
      var assetsManager = new AssetsManager(scene);
      assetsManager.useDefaultLoadingScreen = false;
      var stageTask = assetsManager.addMeshTask("stageTask", "", stageUrl, "")
      // stageTask.onSuccess = (meshTask) => {
      //     var mesh = meshTask.loadedMeshes[1];
      //     mesh.position = new Vector3(0,1,0);
      //   };
      var clothesTask = assetsManager.addMeshTask("clothTask", "", cloth, "");
      var hairTask = assetsManager.addMeshTask("hairTask", "", hair, "");
      var modelTask = assetsManager.addMeshTask("modelTask", "", model, "");
      assetsManager.onTaskSuccessObservable.add(function(task) {
        console.log('task successful', task)
      })
      assetsManager.onTaskErrorObservable.add(function(task) {
        console.error('task unsuccessful', task)
      })
      assetsManager.onProgressObservable.add(function(remainingCount, totalCount, lastFinishedTask) {
        engine.loadingUIText = 'We are loading the scene. ' + remainingCount + ' out of ' + totalCount + ' items still need to be loaded.';
      })
      assetsManager.onTasksDoneObservable.add(function(tasks) {
        var errors = tasks.filter(function(task) {return task.taskState === AssetTaskState.ERROR});
        if (errors.length === 0) showScene();
      })
      
      assetsManager.load();
      const camera1 = new ArcRotateCamera(
        "camera1",
        Math.PI / 2,
        Math.PI / 2,
        0.5,
        new Vector3(0, 1, 0)
      );
      camera1.setPosition(new Vector3(0, 1.2, 1));
      camera1.radius = 3;
      camera1.lowerRadiusLimit=2
      camera1.upperRadiusLimit=4
      camera1.upperBetaLimit = Math.PI/2
      camera1.lowerBetaLimit = 0;
      camera1.panningDistanceLimit = 0.0001;
      camera1.wheelDeltaPercentage = 0.01;
      camera1.pinchDeltaPercentage = 0.01; 
      
      scene.registerBeforeRender(function () {
        light1.position = camera1.position;
      });

      scene.activeCamera = camera1;
      scene.activeCamera.attachControl(reactCanvas, true);
      //scene.createDefaultEnvironment();
   
      var hdrTexture = new CubeTexture.CreateFromPrefilteredData("./environment.env", scene);
      scene.environmentTexture = hdrTexture;
        
      // All Lights
      let light1 = new DirectionalLight("DirectionalLight", new Vector3( -1, -2, -1), scene);
      light1.Color3 = new Color4(0,0,10);
      light1.intensity = 3;
      light1.setEnabled(true);
      light1.direction = new Vector3( -0.307, -0.831, -0.464);
      
      let light2 = new DirectionalLight("DirectionalLight2", new Vector3( -1, 2, -1), scene);
      light2.Color3 = new Color4(10,0,0);
      light2.intensity = 1;
      light2.setEnabled(true);
      light2.direction = new Vector3( -0.307, 0.831, -0.464);
    
      reactCanvas.current.width = window.innerWidth;
      reactCanvas.current.height = window.innerHeight - 58;
      return scene;
    };

    let engine = new Engine(reactCanvas.current, true, {
      preserveDrawingBuffer: true,
      stencil: true
    })
    let scene = await createScene(engine);   
    
    function showScene() {  
      engine.runRenderLoop(function() {
        scene.render();
      })
      setUser(false)
    }

    if (window) {
      window.addEventListener('beforeunload', () => {
        scene.dispose();scene = null
      })
    }
  }
  return (
    <div className="choose_wrapper">
      <Creator_Menu/>
      <canvas id="renderCanvas1" ref={reactCanvas} />
    </div>
  );
}
