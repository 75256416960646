import React from "react";
import "./tos.css";
import Header from "../../CommonLayouts/HeaderLayouts";
import Footer from "../../CommonLayouts/FooterLayouts";
import TextyHeader from "../../CommonLayouts/TextyHeader";

export default function Tos() {
  return (
    <>
      <Header />
      <div className="root-tos">
        <div className="container">
          <TextyHeader  text="Terms of Service" />
          <h3 className="h2_class">Indemnification</h3>
          <p className="p_class">
            You agree to indemnify and hold the Operator and its affiliates,
            directors, o fficers, employees, agents, suppliers and licensors
            harmless from and against any liabilities, losses , damages or
            costs, including reasonable attorneys’ fees, incurred in connection
            with or arising from any third party allegations, claims, actions,
            disputes, or demands asserted against any of them as a result of or
            relating to your Content, your use of the Mobile Application and
            Services or any willful misconduct on your part.
          </p>
          <h3 className="h2_class">Severability</h3>
          <p className="p_class">
            All rights and restrictions contained in this Agreement may be
            exercised and shall be applicable and binding only to the extent
            that they do not violate any applicable laws and are intended to be
            limited to the extent necessary so that they will not render this
            Agreement illegal, invalid or unenforceable. If any provision or
            portion of any provision of this Agreement shall be held to be
            illegal, invalid or unenforceable by a court of competent
            jurisdiction, it is the intention of the parties that the remaining
            provisions or portions thereof shall constitute their agreement with
            respect to the subject matter hereof, and all such remaining
            provisions or portions thereof shall remain in full force and
            effect.
          </p>
          <h3 className="h2_class">Dispute resolution</h3>
          <p className="p_class">
            The formation, interpretation, and performance of this Agreement and
            any disputes arising out of it shall be governed by the substantive
            and procedural laws of California, United States without regard to
            its rules on conflicts or choice of law and, to the extent
            applicable, the laws of United States. The exclusive jurisdiction
            and venue for actions related to the subject matter hereof shall be
            the courts located in California, United States, and you hereby
            submit to the personal jurisdiction of such courts. You hereby waive
            any right to a jury trial in any proceeding arising out of or
            related to this Agreement. The United Nations Convention on
            Contracts for the International Sale of Goods does not apply to this
            Agreement.
          </p>
          <h3 className="h2_class">Changes and amendments</h3>
          <p className="p_class">
            We reserve the right to modify this Agreement or its terms relating
            to the Mobile Application and Services at any time, effective upon
            posting of an updated version of this Agreement in the Mobile
            Application. When we do, we will revise the updated date at the
            bottom of this page. Continued use of the Mobile Application and
            Services after any such changes shall constitute your consent to
            such changes. Policy was created with
            https://www.WebsitePolicies.com
          </p>

          <h3 className="h2_class">Acceptance of these terms</h3>
          <p className="p_class">
            You acknowledge that you have read this Agreement and agree to all
            its terms and conditions. By accessing and using the Mobile
            Application and Services you agree to be bound by this Agreement. If
            you do not agree to abide by the terms of this Agreement, you are
            not authorized to access or use the Mobile Application and Services.{" "}
          </p>

          <h3 className="h2_class">Contacting us</h3>
          <p className="p_class">
            If you would like to contact us to understand more about this
            Agreement or wish to contact us concerning any matter relating to
            it, you may send an email to creators@dreamium.io{" "}
          </p>
          <p className="p-0" style={{marginBottom: '0'}}>
            This document was last updated on August 27, 2020{" "}
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}
