import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "./header.css";

import gominiiLogo from "../../../assets/images/landing/gominii_logo_white.png";
import idrops from "../../../assets/images/landing/idrops.svg";
import logo from "../../../assets/Minii_Logo_Header_.png";
import { useState } from "react";
export function Header(props) {
  const location = useLocation();
  const [flag2, setflag2] = useState(false);
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <a className="navbar-brand" href="#">
        <img src={gominiiLogo} className="gominii-logo" />
      </a>

      <span className="d-lg-none">
        <a className="nav-link nav-btn-sm" onClick={props.createMiniiClicked}>
          Create minii
        </a>
      </span>

      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto tw-z-10">
          <li className="nav-item nav-first-item active">
            <a className="nav-link" href="#">
              miniiverse
            </a>
          </li>
          <li className="nav-item nav-item-regular">
            <a
              className="nav-link"
              href="http://www.dreamium.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="d-none d-lg-block">
                <span>Dreamscape</span>
                <span>Open Metaverse</span>
              </div>
              <span className="d-lg-none">Dreamscape Open Metaverse</span>
            </a>
          </li>
          <li className="nav-item">
            <span onClick={() => setflag2(true)}>
              <img src={idrops} className="drop-logo" />
            </span>
          </li>
          <li className="nav-item mobile-none  nav-item-btn">
            <a
              className="nav-link nav-btn nav-lg-btn"
              onClick={props.createMiniiClicked}
            >
              <div>Create your minii</div>
            </a>
          </li>
        </Nav>
      </Navbar.Collapse>
      {flag2 && (
        <div className="popup-box tw-z-10">
          <div className="box picture_width">
            {/* <div className="popup_text_title">Create your minii</div> */}
            <span
              className="close-icon mt-1 mr-1"
              onClick={() => setflag2(false)}
            >
              <img src="/close.png" />
            </span>
            <div className="mb-3">
              <img src="/idrops.png" alt="" />
            </div>
          </div>
        </div>
      )}
    </Navbar>
  );
}
