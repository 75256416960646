import {createContext, useState,useContext} from 'react'

const AvatarObject=createContext()
const AvatarObject_setstate=createContext()
const GenderAvatar=createContext()

 export default function ObjectProvider({ children}){
    const [avatar_ob, setAvatar_ob] = useState({});
     const [gender_avatar, setGender_avatar] = useState('');
     return(
         <GenderAvatar.Provider value={{gender_avatar,setGender_avatar}}>
   < AvatarObject.Provider value={avatar_ob}    >
       < AvatarObject_setstate.Provider value={setAvatar_ob}    >
       {children}
</AvatarObject_setstate.Provider>
       < /AvatarObject.Provider  >
         </GenderAvatar.Provider>
             )}
 export function useAvatarState(){
    return useContext(AvatarObject)
}
export function useAvatarSetState(){
    return useContext(AvatarObject_setstate)
}
export function useGender(){
    return useContext(GenderAvatar)
}
